import "./App.css";
import { useCallback, useEffect, useState } from "react";

const styles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: "#ffd41b",
  height: "90vh",
};

function App() {
  const [done, setDone] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");
  const [invalid, setInvalid] = useState(false);

  const get = useCallback(async (suffix) => {
    console.log("getting");
    try {
      let results = await fetch("https://api.backoffice.eatirvins.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
          {
            qrGet(suffix: "${suffix}") {
              data {
                id
                destination
                hits
              }
            }
          }
        `,
        }),
      });
      const res = await results.json();
      const data = res?.data?.qrGet?.data?.[0];
      if (data) {
        const { id, destination, hits } = data;
        if (id && destination) {
          set(id, hits);
          setRedirectTo(destination);
        }
      } else {
        setInvalid(true);
      }
    } catch (err) {
      setInvalid(true);
      console.error(err.message);
    }
  }, []);

  async function set(id, hits) {
    try {
      console.log("setting");
      const _hits = hits ? Number(hits) + 1 : 1;
      await fetch("https://api.backoffice.eatirvins.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            mutation {
              qrUpdate(input: { id: "${id}", hits: ${_hits} }) {
                data {
                  id
                }
              }
            }
          `,
        }),
      });
    } catch (err) {
      setInvalid(true);
      console.error(err.message);
    } finally {
      setDone(true);
    }
  }

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/") {
      setInvalid(true);
    } else if (pathname.length >= 2) {
      get(pathname.replace("/", ""));
    }
  }, [get]);

  useEffect(() => {
    if (done) {
      if (redirectTo) {
        setTimeout(() => {
          window.location.href = redirectTo;
        }, 500);
      } else {
        setInvalid(true);
      }
    }
  }, [done, redirectTo]);

  const handleClick = () => (window.location.href = "https://www.eatirvins.sg/");

  return (
    <div style={styles}>
      {invalid ? (
        <>
          <h2 style={{ fontFamily: "sans-serif", marginBottom: "1rem" }}>Seems like the QR Code has expired...</h2>
          <h2 onClick={handleClick} style={{ fontFamily: "sans-serif", cursor: "pointer", textDecoration: "underline", color: "blue" }}>
            Visit our website here!
          </h2>
        </>
      ) : (
        <>
          <img src={require("./logo.gif")} width={"300px"} alt="IRV SPINNING" />
          <h2 style={{ fontFamily: "sans-serif" }}>Redirecting...</h2>
        </>
      )}
    </div>
  );
}

export default App;
